// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-index-jsx": () => import("/opt/build/repo/src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-pages-benelux-jsx": () => import("/opt/build/repo/src/pages/benelux.jsx" /* webpackChunkName: "component---src-pages-benelux-jsx" */),
  "component---src-pages-britsabroad-jsx": () => import("/opt/build/repo/src/pages/britsabroad.jsx" /* webpackChunkName: "component---src-pages-britsabroad-jsx" */),
  "component---src-pages-cestina-jsx": () => import("/opt/build/repo/src/pages/cestina.jsx" /* webpackChunkName: "component---src-pages-cestina-jsx" */),
  "component---src-pages-demand-jsx": () => import("/opt/build/repo/src/pages/demand.jsx" /* webpackChunkName: "component---src-pages-demand-jsx" */),
  "component---src-pages-espanol-jsx": () => import("/opt/build/repo/src/pages/espanol.jsx" /* webpackChunkName: "component---src-pages-espanol-jsx" */),
  "component---src-pages-europamivagyunk-jsx": () => import("/opt/build/repo/src/pages/europamivagyunk.jsx" /* webpackChunkName: "component---src-pages-europamivagyunk-jsx" */),
  "component---src-pages-eushenanigans-jsx": () => import("/opt/build/repo/src/pages/eushenanigans.jsx" /* webpackChunkName: "component---src-pages-eushenanigans-jsx" */),
  "component---src-pages-eusvenskar-jsx": () => import("/opt/build/repo/src/pages/eusvenskar.jsx" /* webpackChunkName: "component---src-pages-eusvenskar-jsx" */),
  "component---src-pages-evropaenasha-jsx": () => import("/opt/build/repo/src/pages/evropaenasha.jsx" /* webpackChunkName: "component---src-pages-evropaenasha-jsx" */),
  "component---src-pages-getvoting-jsx": () => import("/opt/build/repo/src/pages/getvoting.jsx" /* webpackChunkName: "component---src-pages-getvoting-jsx" */),
  "component---src-pages-hellada-jsx": () => import("/opt/build/repo/src/pages/hellada.jsx" /* webpackChunkName: "component---src-pages-hellada-jsx" */),
  "component---src-pages-hey-jsx": () => import("/opt/build/repo/src/pages/hey.jsx" /* webpackChunkName: "component---src-pages-hey-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-letthemknow-jsx": () => import("/opt/build/repo/src/pages/letthemknow.jsx" /* webpackChunkName: "component---src-pages-letthemknow-jsx" */),
  "component---src-pages-noieuropei-jsx": () => import("/opt/build/repo/src/pages/noieuropei.jsx" /* webpackChunkName: "component---src-pages-noieuropei-jsx" */),
  "component---src-pages-nutzedeinwahlrecht-jsx": () => import("/opt/build/repo/src/pages/nutzedeinwahlrecht.jsx" /* webpackChunkName: "component---src-pages-nutzedeinwahlrecht-jsx" */),
  "component---src-pages-ourtime-jsx": () => import("/opt/build/repo/src/pages/ourtime.jsx" /* webpackChunkName: "component---src-pages-ourtime-jsx" */),
  "component---src-pages-overseas-jsx": () => import("/opt/build/repo/src/pages/overseas.jsx" /* webpackChunkName: "component---src-pages-overseas-jsx" */),
  "component---src-pages-pass-it-on-jsx": () => import("/opt/build/repo/src/pages/pass-it-on.jsx" /* webpackChunkName: "component---src-pages-pass-it-on-jsx" */),
  "component---src-pages-petition-jsx": () => import("/opt/build/repo/src/pages/petition.jsx" /* webpackChunkName: "component---src-pages-petition-jsx" */),
  "component---src-pages-polacy-jsx": () => import("/opt/build/repo/src/pages/polacy.jsx" /* webpackChunkName: "component---src-pages-polacy-jsx" */),
  "component---src-pages-register-jsx": () => import("/opt/build/repo/src/pages/register.jsx" /* webpackChunkName: "component---src-pages-register-jsx" */),
  "component---src-pages-registertovote-jsx": () => import("/opt/build/repo/src/pages/registertovote.jsx" /* webpackChunkName: "component---src-pages-registertovote-jsx" */),
  "component---src-pages-remainparty-jsx": () => import("/opt/build/repo/src/pages/remainparty.jsx" /* webpackChunkName: "component---src-pages-remainparty-jsx" */),
  "component---src-pages-revoke-jsx": () => import("/opt/build/repo/src/pages/revoke.jsx" /* webpackChunkName: "component---src-pages-revoke-jsx" */),
  "component---src-pages-romana-jsx": () => import("/opt/build/repo/src/pages/romana.jsx" /* webpackChunkName: "component---src-pages-romana-jsx" */),
  "component---src-pages-share-jsx": () => import("/opt/build/repo/src/pages/share.jsx" /* webpackChunkName: "component---src-pages-share-jsx" */),
  "component---src-pages-simple-jsx": () => import("/opt/build/repo/src/pages/simple.jsx" /* webpackChunkName: "component---src-pages-simple-jsx" */),
  "component---src-pages-talk-jsx": () => import("/opt/build/repo/src/pages/talk.jsx" /* webpackChunkName: "component---src-pages-talk-jsx" */),
  "component---src-pages-theremainparty-jsx": () => import("/opt/build/repo/src/pages/theremainparty.jsx" /* webpackChunkName: "component---src-pages-theremainparty-jsx" */),
  "component---src-pages-voice-jsx": () => import("/opt/build/repo/src/pages/voice.jsx" /* webpackChunkName: "component---src-pages-voice-jsx" */),
  "component---src-pages-vote-jsx": () => import("/opt/build/repo/src/pages/vote.jsx" /* webpackChunkName: "component---src-pages-vote-jsx" */),
  "component---src-pages-votebot-jsx": () => import("/opt/build/repo/src/pages/votebot.jsx" /* webpackChunkName: "component---src-pages-votebot-jsx" */),
  "component---src-pages-votepourtonavenir-jsx": () => import("/opt/build/repo/src/pages/votepourtonavenir.jsx" /* webpackChunkName: "component---src-pages-votepourtonavenir-jsx" */),
  "component---src-pages-wearealleuropeans-jsx": () => import("/opt/build/repo/src/pages/wearealleuropeans.jsx" /* webpackChunkName: "component---src-pages-wearealleuropeans-jsx" */),
  "component---src-pages-where-jsx": () => import("/opt/build/repo/src/pages/where.jsx" /* webpackChunkName: "component---src-pages-where-jsx" */),
  "component---src-pages-write-jsx": () => import("/opt/build/repo/src/pages/write.jsx" /* webpackChunkName: "component---src-pages-write-jsx" */),
  "component---src-pages-yourvotematters-jsx": () => import("/opt/build/repo/src/pages/yourvotematters.jsx" /* webpackChunkName: "component---src-pages-yourvotematters-jsx" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

